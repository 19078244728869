/* .airline-deals-style{
    grid-template-columns: 1fr 1fr 1fr;
}
@media only screen and (max-width:1150px){
    .airline-deals-style{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width:800px){
    .airline-deals-style{
        grid-template-columns: 1fr;
    }
}
@media only screen and (max-width:390px){

} */
.airline-deals-style{
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media only screen and (max-width:1020px){
    .airline-deals-style{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media only screen and (max-width:790px){
    .airline-deals-style{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width:500px){
    .airline-deals-style{
        grid-template-columns: 1fr;
    }
}