/* .exclusive-deals-style{

} */
.exclusive-deals-style{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* grid-template-rows: 200px 200px; */
    column-gap: 30px;
    row-gap: 30px;
}
@media only screen and (max-width:1020px){
    .exclusive-deals-style{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media only screen and (max-width:790px){
    .exclusive-deals-style{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width:500px){
    .exclusive-deals-style{
        grid-template-columns: 1fr;
    }
}