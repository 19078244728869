@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {

  /* [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  } */
}

/* .DateRangePicker_picker {
  transform: translateX(40%) !important;
  margin-top: 1px !important;
} */
.CalendarDay__selected_span {
  background-color: lightgray !important;
}

.CalendarDay__selected_span:hover {
  background-color: gray !important;
}

.CalendarDay__default:hover {
  background-color: lightgray !important;
}

.SingleDatePicker {
  /* z-index: 0 !important; */
  flex: 0.5 !important;
}

.DayPickerNavigation_leftButton__horizontalDefault_5 {
  border-radius: 100% !important;
  padding: 17px !important;
}

.DayPickerNavigation_leftButton__horizontalDefault_5 svg {
  width: 15px !important;
  position: absolute;
  z-index: 100;
}

.SingleDatePicker_picker {
  /* background-color: red !important; */
  z-index: 11 !important;
  margin-top: 1px !important;
}

.cookie-container {
  width: 100%;
  background-color: #0a4293 !important;
  display: flex;
}

.cookie {
  display: flex;
  align-items: center;
  justify-content: center;
}

@supports (-webkit-touch-callout: none) {
  @media only screen and (max-width:750px) {
    .cookie-container {
      width: 100%;
      background-color: #0a4293 !important;
      display: flex;
    }

    .cookie {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cookie-btn-container {
      border: 2px solid white !important;
      border-radius: 10px;
      background-color: white !important;
      position: static !important;
      transform: translate(-60%, -50%);
    }

    .cookie-btn {
      color: #0A4293 !important;
      background-color: white !important;
      margin: auto;
    }

    .cookie-text {
      padding: 20px;
      padding-right: 40px;
      height: 100% !important;
      width: 70%;
      text-align: left;
    }
  }

  @media only screen and (max-width:420px) {
    .cookie-container {
      width: 100%;
      background-color: #0a4293 !important;
      display: flex;
      flex-direction: column !important;
      justify-content: space-between !important;
    }

    .cookie {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cookie-btn-container {
      border: 2px solid white !important;
      border-radius: 10px;
      background-color: white !important;
      position: static !important;
      transform: translate(-60%, -50%);
    }

    .cookie-btn {
      color: #0A4293 !important;
      background-color: white !important;
      margin: auto;
    }

    .cookie-text {
      padding: 20px;
      padding-right: 40px;
      height: 100% !important;
      width: 70%;
      text-align: left;
    }

    .cookie-text h2 {}

    .cookie-text {}

    .cookie-content {
      max-height: fit-content !important;
    }
  }
}

.cookie-btn-container {
  border: 2px solid white !important;
  border-radius: 10px;
  background-color: white !important;
  position: static !important;
  transform: translate(-60%, -50%);
}

.cookie-btn {
  color: #0a4293 !important;
  background-color: white !important;
  margin: auto;
}

.cookie-text {
  padding: 20px;
  padding-right: 40px;
  height: 100% !important;
  width: 70%;
  text-align: left;
}

@media only screen and (max-width:750px) {
  .cookie {
    flex-direction: column !important;
    justify-items: center;
    align-items: center;
  }

  .cookie-text {
    padding: 0;
    height: auto;
    width: auto;
    text-align: center;
  }

  .cookie-btn-container {
    border: 2px solid white !important;
    border-radius: 10px;
    background-color: white !important;
    position: none;
    transform: none;
    margin: 10px auto;
  }

  .cookie-btn {
    color: #0a4293 !important;
    background-color: white !important;
    font-weight: 700;
    margin: 5px !important;
  }

  .cookie-container {
    align-items: baseline;
    background: #0a4293;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    position: fixed;
    width: 100% !important;
    /* margin: auto;
    left: 50% !important;
    transform: translate(-50%) !important; */
    z-index: 999;
    bottom: 0px;
    font-size: 14px !important;
    /* margin: 0 30px!important; */
    border-radius: 10px;
  }

  .cookie-content {
    margin: 5px auto !important;
    flex: content !important;
  }

  .cookie {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin: auto !important;
    padding: 5px;
  }

  @supports(-webkit-touch-callout:none) {
    .cookie {
      flex-direction: column !important;
      justify-items: center !important;
      align-items: center !important;
    }

    .cookie-text {
      padding: 0 !important;
      height: auto !important;
      width: auto !important;
      text-align: center !important;
    }

    .cookie-btn-container {
      border: 2px solid white !important;
      border-radius: 10px !important;
      background-color: white !important;
      position: none !important;
      transform: none !important;
      margin: 10px auto !important;
    }

    .cookie-btn {
      color: #0a4293 !important;
      background-color: white !important;
      font-weight: 700 !important;
      margin: 5px !important;
    }

    .cookie-container {
      align-items: baseline;
      background: #0a4293;
      color: white;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      position: fixed;
      width: 100% !important;
      /* margin: auto;
      left: 50% !important;
      transform: translate(-50%) !important; */
      z-index: 999;
      bottom: 0px;
      font-size: 14px !important;
      /* margin: 0 30px!important; */
      border-radius: 10px;
    }

    .cookie-content {
      margin: 5px auto !important;
      flex: content !important;
    }

    .cookie {
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
      margin: auto !important;
      padding: 5px !important;
    }
  }

}




.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.theme-scrollbar::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

.theme-scrollbar::-webkit-scrollbar-thumb {
  background: #0a4293;
  border-radius: 100px;
}