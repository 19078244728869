@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";



.radio-btn {
    &:checked {
        background-color: rgb(var(--c-primary-600)) !important;
    }

    &:focus {
        box-shadow: 0 0 0 !important;
    }
}

.line {
    height: 1.5px;
    width: 100%;
    background-color: rgba(170, 169, 169, 0.503);
    margin-top: 2em;
}