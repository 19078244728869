.img-container {
  background: url("../../../../images/newHome/half-circle.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.tourist {
  background-size: contain;
  /* width: 75%; */
  width: 90%;
}

@media only screen and (max-width: 800px) {
  .tourist {
    background-size: contain;
    width: 100%;
  }
}
.content .icons-container .icon {
  width: 7%;
  height: 7%;
  min-width: 7%;
  min-height: 7%;
}

.content .icons-container .icon img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

/*# sourceMappingURL=easySteps.css.map */
@media only screen and (max-width: 640px) {
  .content .icons-container .icon {
    width: 7%;
    height: 7%;
    min-width: 7%;
    min-height: 7%;
  }
}/*# sourceMappingURL=easySteps.css.map */